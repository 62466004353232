
.teamProfileImage {
    object-fit: fit;
    border-radius: 200px;
    background-color: var(--color-primary-10);
    border: var(--standard-border)
}

.teamProfileLocation {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin: 0;
    color: var(--color-primary-50)
}

.teamName {
    font-weight: 500;
}