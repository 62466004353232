.card {
    box-sizing: border-box;
    height: auto; /* 200px */
    width: 1fr;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 10px;
    background-color: #ffffff;
    overflow: hidden;
    border-radius: 18px;
    -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.card::-webkit-scrollbar {
    display: none;
}