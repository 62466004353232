.card {
    padding: 24px;
    width: 100%;
    max-width: 450px;
}

.page {
    background: linear-gradient(124deg, rgba(255, 51, 197, 0.07) 0%, hsla(250, 100%, 40%, 0.06) 100%);
    padding: 0px;
    min-height: 100vh;
}


@media screen and (max-width: 600px) {
    .card {
        border-radius: 0;
        padding: 20px;
        box-shadow: none;
        display: flex;
        justify-content: column;
    }

    .page {
        background: white;
    }
}
    